import axios from 'axios'

const domain = process.env.VUE_APP_URL_AWS_MONITOR

const axiosInstance = axios.create({
  domain,
  baseURL: process.env.VUE_APP_URL_AWS_MONITOR,
})

axiosInstance.interceptors.request.use(function (config) {
  config.headers.authorization = JSON.parse(localStorage.getItem('user')).token
  return config
}, function (error) {
  return Promise.reject(error)
})

export default axiosInstance
