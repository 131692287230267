<template>
  <v-container
    fluid
  >
    <v-row
      class="mb-7 fade-transition"
      justify="center"
    >
      <v-col
        class="text-center"
        cols="12"
      >
        <v-btn
          class="ml-2"
          color="#FF5722"
          text
          @click="updateServersInfo()"
        >
          Atualizar
          <v-divider
            class="mx-4"
            vertical
          />
          <v-icon>mdi-update</v-icon>
        </v-btn>
        <v-row
          justify="center"
        >
          <v-col
            cols="12"
            md="11"
          >
            <base-material-card
              icon="mdi-server"
              class="px-3 py-3"
              color="#FF5722"
              :loading="loading"
            >
              <v-row>
                <v-col
                  v-for="(server, index) in serversData"
                  :key="index"
                  cols="12"
                  sm="6"
                  md="4"
                  lg="3"
                  class="pa-1"
                >
                  <v-expansion-panels
                    v-if="!loading"
                    transition="fade-transition"
                    popout
                    elevation="0"
                  >
                    <v-expansion-panel>
                      <v-expansion-panel-header
                        :color="color(server)"
                        style="height: 45px"
                      >
                        <h2 class="white--text font-weight-regular text-truncate align-center mt-3 mb-3">
                          {{ server.server }}
                        </h2>
                        <template v-slot:actions>
                          <v-icon color="white">
                            mdi-chevron-down
                          </v-icon>

                          <v-menu
                            right
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                icon
                                v-bind="attrs"
                                class="mx-0 px-0"
                                v-on="on"
                              >
                                <v-icon color="white">
                                  mdi-dots-vertical
                                </v-icon>
                              </v-btn>
                            </template>

                            <v-list>
                              <v-list-item @click="requestBackup(server.server)">
                                <v-list-item-icon>
                                  <v-icon>mdi-cloud-upload-outline</v-icon>
                                </v-list-item-icon>
                                <v-list-item-content>
                                  <v-list-item-title>Requisitar Backup</v-list-item-title>
                                </v-list-item-content>
                              </v-list-item>

                              <v-list-item @click="setDevMode(server.server, !server.devMode)">
                                <v-list-item-icon>
                                  <v-icon>mdi-dev-to</v-icon>
                                </v-list-item-icon>
                                <v-list-item-content>
                                  <v-list-item-title>{{ server.devMode ? 'Desabilitar modo de desenvolvimento' : 'Habilitar modo de desenvolvimento' }}</v-list-item-title>
                                </v-list-item-content>
                              </v-list-item>

                              <v-list-item @click="removeServer(server.server)">
                                <v-list-item-icon>
                                  <v-icon>mdi-trash-can</v-icon>
                                </v-list-item-icon>
                                <v-list-item-content>
                                  <v-list-item-title>Remover servidor</v-list-item-title>
                                </v-list-item-content>
                              </v-list-item>
                            </v-list>
                          </v-menu>
                        </template>
                      </v-expansion-panel-header>
                      <v-card
                        v-for="(message, messageIndex) in server.alertMessage"
                        :key="messageIndex"
                        color="warning"
                        tile
                        class="ma-0 pa-0"
                      >
                        <h3 class="white--text font-weight-regular align-center pa-1">
                          {{ message }}
                        </h3>
                      </v-card>
                      <v-expansion-panel-content>
                        <h2
                          v-if="server.devMode"
                          class="font-weight-regular"
                        >
                          <br>
                          <strong>
                            ────────────────────<br>
                            {{ server.devMode ? 'Modo de desenvolvimento' : '' }}
                            ────────────────────<br>
                          </strong>
                        </h2>
                        <br>
                        <h2 class="font-weight-regular">
                          <b>Status geral: {{ server.status ? 'Online' : 'Offline' }}</b>
                        </h2>
                        <h3 class="font-weight-regular mb-1">
                          <b><span class="h6">SQL Server Status:</span> Ativo</b>
                        </h3>
                        <h3 class="font-weight-regular mb-0 mt-2 h6">
                          <b>Uptime:</b>{{ server.uptime }} horas
                        </h3>

                        <h3 class="font-weight-regular mb-0 ml-3">
                          <b>Ultimo backup: {{ server.lastBackup ? momentFormat(server.lastBackup) : 'Nenhum dado' }}</b>
                        </h3>

                        <br>
                        <h3 class="font-weight-bold mb-0 mt-2 h6">
                          <b>Endereço: </b><br><a>{{ `${server.host.subdomain}.${server.host.cloudflare_zone_name}` }}</a>
                        </h3>
                        <h4 class="font-weight-bold mb-0 mt-1 h6">
                          <b>IP: </b><a>{{ `${server.host.ip}` }}</a>
                        </h4>

                        <div>
                          <h3 class="font-weight-regular mb-0 mt-2 h6">
                            <b>Uso médio da CPU:</b>
                          </h3>
                          <h3 class="font-weight-regular mb-0 ml-3">
                            1 min: {{ (server.cpuLoadAvg.oneMin * 100).toFixed(0) }}%
                          </h3>
                          <h3 class="font-weight-regular mb-0 ml-3">
                            5 min: {{ (server.cpuLoadAvg.fiveMin * 100).toFixed(0) }}%
                          </h3>
                          <h3 class="font-weight-regular mb-0 ml-3">
                            15 min: {{ (server.cpuLoadAvg.fifteenMin * 100).toFixed(0) }}%
                          </h3>
                        </div>

                        <div>
                          <h3 class="font-weight-regular mb-0 mt-2 h6">
                            <b>Informações do disco:</b>
                          </h3>
                          <h3 class="font-weight-regular mb-0 ml-3">
                            Montado em: {{ server.diskInfo.Name }}
                          </h3>
                          <h3 class="font-weight-regular mb-0 ml-3">
                            Tamanho: {{ tamanhoArquivo(server.diskInfo.Size) }}
                          </h3>
                          <h3 class="font-weight-regular mb-0 ml-3">
                            Espaço utilizado: {{ tamanhoArquivo(server.diskInfo.Used) }}
                          </h3>
                          <h3 class="font-weight-regular mb-0 ml-3">
                            Espaço disponivel: {{ tamanhoArquivo(server.diskInfo.Available) }}
                          </h3>
                          <h3 class="font-weight-regular mb-0 ml-3">
                            <b>Porcentagem utilizada: {{ server.diskInfo.percentUsed }}%</b>
                          </h3>
                        </div>
                        <div>
                          <h3 class="font-weight-regular mb-0 mt-2 h6">
                            <b>Informações da memória:</b>
                          </h3>
                          <h3 class="font-weight-regular mb-0 ml-3">
                            Total: {{ tamanhoArquivo(server.memory.total) }}
                          </h3>
                          <h3 class="font-weight-regular mb-0 ml-3">
                            Utilizado: {{ tamanhoArquivo(server.memory.used) }}
                          </h3>
                          <h3 class="font-weight-regular mb-0 ml-3">
                            Disponivel: {{ tamanhoArquivo(server.memory.available) }}
                          </h3>
                          <h3 class="font-weight-regular mb-0 ml-3">
                            Livre: {{ tamanhoArquivo(server.memory.free) }}
                          </h3>
                          <h3 class="font-weight-regular mb-0 ml-3">
                            compartilhada: {{ tamanhoArquivo(server.memory.shared) }}
                          </h3>
                          <h3 class="font-weight-regular mb-0 ml-3">
                            Buffer/Cache: {{ tamanhoArquivo(server.memory.cache) }}
                          </h3>
                          <h3 class="font-weight-regular mb-0 ml-3">
                            <b>Porcentagem utilizada: {{ (100 - server.memory.available / server.memory.total * 100).toFixed(0) }}%</b>
                          </h3>
                        </div>
                        <div class="mt-3">
                          <h3
                            class="font-weight-regular h6 m-0 text-primary mousePointer"
                            @click="showFilesInfo(server.filesInfo)"
                          >
                            <u>Arquivos SQL Server</u>
                          </h3>
                        </div>

                        <h3 class="font-weight-regular mb-1 mt-2">
                          <b>Ultima atualização: </b> {{ momentFormat(server.updatedAt) }}
                        </h3>
                        <small
                          class="font-weight-regular text-secondary p-2 m-0"
                          style="font-size: 10px"
                        >Informações referentes ao horário da ultima atualização.</small>

                        <div
                          class="card-footer mousePointer"
                          data-toggle="collapse"
                          :href="'#collapseCard'+index"
                          role="button"
                          aria-expanded="false"
                          :aria-controls="'#collapseCard'+index"
                          :class="(server.alertMessage.length > 0) ? 'bg-warning text-white' : 'bg-light'"
                        >
                          <small class="text-muted">Visto por ultimo {{ momentFromNow(server.updatedAt) }}</small>
                        </div>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>
                </v-col>
              </v-row>
            </base-material-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-dialog
      v-model="dialogArquivosSQL"
      max-width="700px"
    >
      <v-card>
        <v-btn
          icon
          color="error"
          @click="dialogArquivosSQL = false"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-card-title>
          <h2 class="text-center font-weight-medium align-center mt-3 mb-3">
            SQL Server Files Info
          </h2>
        </v-card-title>
        <v-card-text>
          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">
                    Nome
                  </th>
                  <th class="text-left">
                    Tamanho
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(file, index) in serverFiles"
                  :key="index"
                >
                  <td>{{ file.name }}</td>
                  <td>{{ tamanhoArquivo(file.size / 1024) }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="#E0115F"
            text
            @click="dialogArquivosSQL = (!dialogArquivosSQL)"
          >
            Fechar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="backupResult.open"
      max-width="700px"
      persistent
    >
      <v-card>
        <v-card-title>
          <h2
            v-if="backupResult.loading"
            class="text-center font-weight-medium align-center mt-3 mb-3"
          >
            Executando Backup
          </h2>
          <h2 v-else>
            Backup finalizado
          </h2>
          <h3 v-if="backupResult.loading">
            Por favor aguarde, este processo pode levar alguns minutos.
          </h3>
          <h5>Caso esteja demorando muito ( mais de 10 minutos ) recarregue o site e vá até a página Arquivos AWS e procure pelo backup</h5>
        </v-card-title>
        <v-card-text class="text-center">
          <div v-if="backupResult.loading">
            <v-progress-circular
              indeterminate
              color="red"
            />
          </div>
          <div v-else>
            <h3>Nome do arquivo: {{ backupResult.backupName }}</h3>
            <v-btn
              color="primary"
              :to="`/backup/files?s=${backupResult.backupName}`"
            >
              Ir para arquivo
            </v-btn>
            <br>
            <v-expansion-panels class="mt-2">
              <v-expansion-panel>
                <v-expansion-panel-header>
                  <h3>Resultado</h3>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  {{ backupResult.resultRaw }}
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </div>
        </v-card-text>
        <v-card-actions v-if="!backupResult.loading">
          <v-spacer />
          <v-btn
            color="#E0115F"
            text
            @click="backupResult.open = false"
          >
            Fechar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
  import filesize from 'filesize'
  import path from 'path'
  import moment from 'moment'
  import axiosAWSMonitor from '../../../axios-AWS-Monitor'
  moment.locale('pt-br')

  export default {
    name: 'ServersAWS',

    data () {
      return {
        serversData: [],
        updateStatus: 0,
        lastUpdated: '',
        dialogArquivosSQL: false,
        serverFiles: [],
        loading: false,
        backupResult: {
          open: false,
          loading: false,
          resultRaw: '',
          backupName: 'backup-stage-20210820-1740.zip',
        },
      }
    },
    computed: {
    },
    created () {
      this.updateServersInfo()
      setInterval(() => { this.updateServersInfo() }, 10 * 60 * 1000)
    },

    methods: {
      color (val) {
        if (val.devMode) {
          return '#2b7df0'
        } else if (val.status) {
          return '#388E3C' // verde
        } else if (val.status && val.alertMessage.length > 0 && val.alertMessage.length < 3) {
          return 'ff6600' // amarelo
        } else {
          return '#F44336' // vermelho
        }
      },
      async updateServersInfo () {
        this.loading = true
        const serversInfo = await axiosAWSMonitor.get('/aws-monitor/get-status').then(r => r.data)
        this.serversData = serversInfo.map(item => this.getOverallStatus(item))
        this.loading = false
        this.lastUpdated = moment(new Date(Date.now())).format('lll')
        return this.serversData
      },
      getOverallStatus (server) {
        let status = true
        const message = []

        server.uptime = ((server.uptime.split(':')[0] * 1) + (server.uptime.split(':')[1] / 60)).toFixed(1)

        if (!server.diskInfo.percentUsed || server.diskInfo.percentUsed > 75) {
          message.push('Menos de 25% de espaço disponível no disco.')
        }

        if (!server.updatedAt || new Date(server.updatedAt).getTime() < (Date.now() - (20 * 60 * 1000))) {
          status = false
          message.push('O servidor está levanto tempo demais para atualizar suas informações.')
        }

        if (!server.mssqlStatus || server.mssqlStatus !== 'active') {
          status = false
          message.push('O SQL Server está offline.')
        }

        if (server.cpuLoadAvg.fiveMin > 0.9 && server.cpuLoadAvg.fifteenMin > 0.8) {
          message.push('Uso médio de CPU alto. O servidor pode estar sofrendo com lentidão.')
        }

        if (server.memory.available < 200 * 1000) {
          message.push(`Somente ${filesize(server.memory.available * 1000, 1)} de memória disponível.`)
        }

        if (server.uptime > 24) {
          message.push('O servidor não reinicia a mais de 24 horas.')
        }

        server.filesInfo.map((file) => {
          if (!file.size || file.size > (200 * 1024 * 1024)) { // ( MB * MB (size) * KB (size) )
            if (path.extname(file.name) !== '.mdf' && file.name.split('_')[file.name.split('_').length - 1] !== 'Data') {
              message.push(`O arquivo de log ${file.name} ultrapassou 200MB.`)
            }
          }
        })

        server.alertMessage = message
        server.status = status

        return server
      },
      async removeServer (serverName) {
        await axiosAWSMonitor.post(`/aws-monitor/remove/${serverName}`)
        this.updateServersInfo()
      },
      async setDevMode (serverName, status) {
        await axiosAWSMonitor.post(`/aws-monitor/dev-mode/${serverName}?value=${!!status}`)
        this.updateServersInfo()
      },
      async requestBackup (serverName, status) {
        this.backupResult.open = true
        this.backupResult.loading = true

        axiosAWSMonitor.defaults.timeout = Infinity
        await axiosAWSMonitor.post(`/aws-monitor/request-backup/${serverName}`, {}, {
          headers: {
            'Access-Control-Allow-Origin': '*',
          },
        })
          .then(response => {
            this.backupResult.resultRaw = response.data
            this.backupResult.backupName = response.data.match(/BACKUP_NAME=(.*.zip)/)[1]
          })
          .catch()

        this.backupResult.loading = false
      },
      showFilesInfo (diskInfo) {
        this.serverFiles = [...diskInfo]
        this.dialogArquivosSQL = true
      },
      momentFormat (entry) {
        return moment(entry).format('lll')
      },
      momentFromNow (entry) {
        return moment(entry).fromNow()
      },

      tamanhoArquivo (val) {
        return filesize(val * 1024)
      },
    },
  }
</script>
